import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ViitorulMobile from "../images/viitorul-m.jpg"
import ViitorulDesktop from "../images/viitorul.jpg"
import PPDonatii from "../images/pp-donatii.jpg"
import PPRecicleaza from "../images/Danone_reciclare_1920x1080.jpg"
import PPBaterii from "../images/pp-baterii.jpg"
import PPCapace from "../images/pp-capace.jpg"
import PPPunga from "../images/pp-punga.jpg"
import PPBonapp from "../images/pp-bonapp.jpg"
import PPBanca from "../images/pp-banca.jpg"
import PPReciclare from "../images/pp-reciclare.jpg"
import PPEcobomus from "../images/pp-ecobonus-mobile.jpg"
import SpatiulVerde from "../images/spatiul-verde.jpg"
import RecyclingGames from "../images/recycling-games.jpg"
import Colecta from "../images/Banner_Colecta_1200x900.jpg"
import AppleImg from "../images/apple.png"
import GoogleImg from "../images/google.png"

const Energie = () => (
  <Layout pageInfo={{ pageName: "campanii" }}>
    <SEO title="Campanii" />
    <div>
      <img
        className="d-md-none"
        src={ViitorulMobile}
        alt=""
      />
      <img
        className="d-none d-md-block w-100"
        src={ViitorulDesktop}
        alt=""
      />
    </div>
    <div className="container">
      <div className="row my-5">
        <div className="col-10 my-5 mx-auto">
          <p className="h5 akko-regular text-center">
            Grija față de planetă vine din micile alegeri pe care le facem zilnic de a folosi cu responsabilitate tot ce are natura mai bun de oferit. La PENNY, ne dorim să îți oferim cât mai multe oportunități de a fi sustenabil, fie că vorbim despre campanii dedicate reciclării ambalajelor sau parteneriate cu organizații care contribuie la reducerea risipei alimentare. Venim în întâmpinarea dorinței tale de a fi parte din schimbare și ne asigurăm că eforturile tale sunt dublate de acțiunile noastre de a face sustenabilitatea cât mai accesibilă tuturor. Astfel, chiar și o simplă vizită în magazinele noastre îți lasă loc pentru alegeri bune și te inspiră să găsești mereu noi modalități de a proteja mediul.
          </p>
        </div>
      </div>
    </div>
    <div id="bonapp" className="section-info section-left row no-gutters">
      <div className="col-12 col-lg-6 order-2 order-lg-1 align-self-center">
        <div className="section-text py-6 py-lg-8 pl-20 pl-lg-6 pl-xl-8 pl-xxl-12 pr-20 pr-xl-8">
          <h1 className="text-success akko-bold mb-3">
            Cu PENNY și bonapp.eco salvezi mâncarea și te bucuri de reduceri.
          </h1>
          <p>
          Suntem mândri să fim parteneri ai bonapp.eco, aplicație care te ajută să salvezi alimente bune de la risipă. Prin intermediul acesteia, poți găsi reduceri de până la 50% la produse de la PENNY, al căror termenul de expirare se apropie, dar care încă sunt la fel de sigure și gustoase. Așa, tu economisești bani, noi le punem în vânzare mai repede și, împreună, facem o schimbare pentru un consum mai responsabil.
          </p>
          <p>
          În prezent, peste 50% dintre magazinele PENNY sunt incluse în aplicație și ne propunem ca până la finalul anului 2024 să extindem programul în toate magazinele noastre.
            </p>
          <p>
          Descarcă aplicația bonapp.eco, bucură-te de prețurile avantajoase și contribuie la combaterea risipei alimentare.
          </p>
          {" "}
          <div style={{ textAlign: "center" }}>
            <a href="https://apps.apple.com/ro/app/bonapp-eco-combate-risipa/id1578646025?l=ro"><img src={AppleImg} style={{ width: "140px", display: "inline", padding: "10px" }} alt="" className="" /></a>
            <a href="https://play.google.com/store/apps/details?id=eco.bonapp.app&pli=1"><img src={GoogleImg} style={{ width: "140px", display: "inline", padding: "10px" }} alt="" className="" /></a>

          </div>
        </div>
      </div>
      <div className="col-12 col-lg-6 order-1 order-lg-2">
        <div
          className="section-bg h-100"
          style={{
            backgroundImage: `url(${PPBonapp})`,
            backgroundSize: "contain",
            backgroundColor: "#EBEBEB",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            minHeight: "500px",
          }}
        />
      </div>
    </div>{" "}
    <div className="section-info section-right row no-gutters">
      <div className="col-12 col-lg-6">
        <img src={Colecta} alt="colecta nationala" className="w-100" />
      </div>
      <div className="col-12 col-lg-6">
        <div className="section-text py-6 py-lg-8 pr-20 pr-lg-6 pr-xl-8 pr-xxl-12 pl-20 pl-xl-8">
          <h1 className="text-success akko-bold mb-3">
            Colecta Națională de Alimente
          </h1>
          <p className="mb-2">
            Colecta Națională de Alimente devine un eveniment marcant în calendarul anual al Băncilor pentru Alimente din România. An de an, cu acest prilej, comunități din toată țara se unesc pentru a oferi hrană și speranță celor aflați în nevoie.
            <br /> <br />
            Pe lângă activitatea zilnică de a salva hrană de la pierdere și risipă, în preajma sărbătorilor de Paște și de Crăciun, Federația Băncilor pentru Alimente din România - FBAR, împreună cu Băncile Regionale pentru Alimente din București, Roman, Cluj, Brașov, Oradea, Timișoara, Constanța, Craiova, Galați organizează Colecta Națională de Alimente, o campanie de solidaritate de amploare, cu un impact semnificativ în viața celor în dificultate care cu greu își procură hrana de zi cu zi.
            <br /> <br />
            Donează și tu în magazinele PENNY și spijina persoanele vulnerabile.
            <br /> <br />
            Regulamentul campaniei este disponibil <a href="https://assets-eu-01.kc-usercontent.com/b50b8cce-450a-0138-7b43-de6bc2f3ad32/11ef637f-2aef-41f8-a567-9ad1d56b9edd/Regulament%20Colecta_FBAR_PENNY_FINAL.pdf" target="_blank">aici</a>.
          </p>
        </div>
      </div>
    </div>
    <div className="section-info section-left row no-gutters">
      <div className="col-12 col-lg-6 order-2 order-lg-1 align-self-center">
        <div className="section-text py-6 py-lg-4 pl-20 pl-lg-6 pl-xl-8 pl-xxl-12 pr-20 pr-xl-8">
          <h1 className="text-success akko-bold mb-3">
            Recycling Games
          </h1>
          <p className="mb-0">
            Every Can Counts România, inițiativa locală a programului european dedicat reciclării dozelor, lansează alături de Bergenbier și PENNY campania Recycling Games, o inițiativă prin care își propun să încurajeze și faciliteze reciclarea dozelor de băutură în București. Campania încurajează publicul să se implice activ în reciclarea dozelor de aluminiu și oferă șansa de a câștiga premii speciale.
            <br /><br />
            Pentru a stimula implicarea și pentru a contribui la creșterea conștientizării importanței reciclării, particianții vor primi o serie de premii. Pentru fiecare 5 doze reciclate, aduse în locațiile campaniei, participanții vor fi recompensați, prin tragere la sorți, cu unul dintre premiile puse în joc la categoria articole sportive (minge fotbal, volei, handbal, pompa, mascotă pluș), cât și cu o doză de bere.
            <br /><br />
            Regulamentul privind desfăşurarea campaniei Recycling Games – Fiecare Doză Contează și lista locațiilor partenere, este disponibil <a target="_blank" href="https://assets-eu-01.kc-usercontent.com/b50b8cce-450a-0138-7b43-de6bc2f3ad32/088038cb-9c87-492d-8223-6b5430473186/Regulament%20Recycling%20Games%202023%20-%20new.pdf">aici</a>.
          </p>
        </div>
      </div>
      <div className="col-12 col-lg-6 order-1 order-lg-2">
        <img src={RecyclingGames} alt="recycling games" className="w-100" />
      </div>
    </div>{" "}
    <div className="section-info section-right row no-gutters">
      <div className="col-12 col-lg-6">
        <img src={SpatiulVerde} alt="spatiul verde" className="w-100" />
      </div>
      <div className="col-12 col-lg-6">
        <div className="section-text py-6 py-lg-8 pr-20 pr-lg-6 pr-xl-8 pr-xxl-12 pl-20 pl-xl-8">
          <h1 className="text-success akko-bold mb-3">
            Cumpără produse Procter & Gamble și noi construim spațiul verde al unei școli
            <br />
            1 produs = 1 contribuție
          </h1>
          <p className="mb-2">
            În perioada 1 noiembrie – 31 decembrie 2023, produsele P&G contribuie la construcția spațiului verde a școlii din Ștefănești.
            <br /> <br />
            Proiectul este realizat cu sprijinul Fundației World Vision Romania, care a identificat locul unde nevoia este reală, pentru școală și comunitate. Produsele participante în campanie sunt: Ariel, Fairy, Swiffer, Mr. Proper, Lenor, always, Pampers, head&shoulders, Old Spice, Pantene, Gillette, Oral-B. Achiziționează oricare dintre aceste produse din magazinele PENNY și vei contribui la amenajarea curții unei școli – un spațiu verde incluziv, sustenabil, unde copiii pot sta pe iarbă sau la umbra copacilor, învățând mai multe despre sustenabilitate și ducând vestea mai departe.
            <br /><br />
            Regulamentul oficial și magazinele participante în campanie sunt disponibile <a href="https://preview-assets-eu-01.kc-usercontent.com/b50b8cce-450a-0138-7b43-de6bc2f3ad32/2aba4578-e275-4f78-bbb8-83f01c11f98f/RO_Regulamentul%20Oficial%20al%20Campaniei%20Promotionale%20%2C%2CContribuie%20si%20tu%20la%20crearea%20unui%20spatiu%20verde%20impreuna%20cu%20Procter%20%26Gamble%20si.PDF" target="_blank">aici</a>.
          </p>
        </div>
      </div>
    </div>
    <div className="section-info section-left row no-gutters">
      <div className="col-12 col-lg-6 order-2 order-lg-1 align-self-center">
        <div className="section-text py-6 py-lg-4 pl-20 pl-lg-6 pl-xl-8 pl-xxl-12 pr-20 pr-xl-8">
          <h1 className="text-success akko-bold mb-3">
            Colectezi tu, câștigăm toți!
          </h1>
          <p className="mb-0">
            În perioada 1 februarie – 30 mai, PET-urile devin ECOBONUS pentru tine și comunitate!<br />
            Vino în oricare dintre cele 18 magazine PENNY din București participante la campania ECOBONUS cu minimum 10 PET-uri și ești înscris automat în tombola săptămânală pentru un ECOBONUS în valoare de 150 de lei, iar la 20 de sticle Dorna rPET colectate pe unul sau pe mai multe tichete eliberate de aparatele automate de colectare, primești instant un ECOBONUS în valoare de 10 lei.
            <br /><br />
            În plus, împreună contribuim la susținerea dezvoltării comunitare: PENNY, Coca-Cola HBC România și GreenPoint Management redirecționează contravaloarea tuturor sticlelor Dorna colectate în perioada campaniei către Fundația Comunitară București pentru dezvoltarea OPEN – un proiect al Asociației Climatosfera ce își propune să revitalizeze pentru comunitate comunității o suprafață verde de 2.400 metri pătrați din campusul studențesc Tei.
            <br /><br />
            Regulamentul oficial și magazinele participante la campanie sunt disponibile <a href="https://assets-eu-01.kc-usercontent.com/b50b8cce-450a-0138-7b43-de6bc2f3ad32/6c1df665-91d7-4265-ab18-0a29acf1e11f/Regulament%20Eco-Bonus%20Final.pdf" class="font-weight-bold" target="_blank">aici</a>.
            <br /><br />
            Lista codurilor câștigătoare este disponibilă <a href="https://assets-eu-01.kc-usercontent.com/b50b8cce-450a-0138-7b43-de6bc2f3ad32/9924a77a-ee2e-4b39-ad67-1e774588c51b/CODURI%20CASTIGATOARE%20%28004%29%20%28002%29%20%288%29.pdf" target="_blank">aici</a>.
            <br /><br />
            Valabiltiatea voucherelor a fost extinsă până pe 1 iulie 2023
            <br /><br />
            Anexa regulamentului oficial se află <a href="https://assets-eu-01.kc-usercontent.com/b50b8cce-450a-0138-7b43-de6bc2f3ad32/b731c45e-d1ae-46a5-8940-0929869c1077/Act%20Aditional%201_EcoBonus_2504.pdf" class="font-weight-bold" target="_blank">aici</a>.
          </p>
        </div>
      </div>
      <div className="col-12 col-lg-6 order-1 order-lg-2">
        <div
          className="section-bg h-100"
          style={{
            backgroundImage: `url(${PPEcobomus})`,
            backgroundSize: "contain",
            backgroundColor: "#E31D2A",
            backgroundPosition: "center bottom",
            backgroundRepeat: "no-repeat",
          }}
        />
      </div>
    </div>{" "}
    <div className="section-info section-right row no-gutters">
      <div className="col-12 col-lg-6">
        <div
          className="section-bg h-100"
          style={{
            backgroundImage: `url(${PPDonatii})`,
            backgroundSize: "contain",
            backgroundColor: "#EBEBEB",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
      </div>
      <div className="col-12 col-lg-6">
        <div className="section-text py-6 py-lg-8 pr-20 pr-lg-6 pr-xl-8 pr-xxl-12 pl-20 pl-xl-8">
          <h1 className="text-success akko-bold mb-3">
            5 lei donați = 1 șansă la familie
          </h1>
          <p className="mb-0">
            Susținem misiunea celor de la Hopes and Homes for Children de a preveni destrămarea familiilor aflate în situații vulnerabile, cu resurse limitate. Abandonul familial este adesea cauzat de dificultăți financiare care împiedică familiile să rămână împreună, în ciuda dorinței părinților de a fi cât mai aproape de copiii lor. Sprijinul tău poate oferi o nouă șansă copiilor care riscă să ajungă în orfelinate de a crește alături de cei dragi. Fiecare donație către organizație contribuie la demersul lor zilnic de a localiza familii aflate în situații precare și de a le oferi asistență și consiliere pentru a preîntâmpina o posibilă separare.
            <br />
            <br />
            Donează 5 lei către Hope and Homes for Children direct din magazinele PENNY și ajută cât mai mulți copii să rămână lângă părinții lor.
          </p>
        </div>
      </div>
    </div>
    <div className="section-info section-left row no-gutters">
      <div className="col-12 col-lg-6 order-2 order-lg-1">
        <div className="section-text py-6 py-lg-8 pl-20 pl-lg-6 pl-xl-8 pl-xxl-12 pr-20 pr-xl-8">
          <h1 className="text-success akko-bold mb-3">
            Aduci la reciclat, ai de câștigat!
          </h1>
          <p className="m-0">
            Reciclarea selectivă este premiată, mai ales la PENNY. Astfel, pentru orice 5 ambalaje de tip Tetra Pak pe care le returnezi, primești cadou o sticla de Activia de băut. Pentru a putea beneficia de produsul gratuit, fiecare participant trebuie să aducă la casa de marcat articolul ales impreuna cu cele 5 ambalaje de tip Tetra Pak.
            <br />
            <br />
            Campania se desfasoara până la 25.02.2025. Mai multe detalii despre regulamentul campaniei de <a
              className="font-weight-bold"
              href="https://preview-assets-eu-01.kc-usercontent.com/b50b8cce-450a-0138-7b43-de6bc2f3ad32/61406095-1d33-495c-9bbf-a3b6de942412/REGULAMENT_Campanie%20%E2%80%9CADUCI%20LA%20RECICLAT%2C%20AI%20DE%20C%C3%82%C8%98TIGAT%21%E2%80%9C%20.pdf"
              target="_blank"
            >
              aici
            </a> si în actele adiționale de <a
              className="font-weight-bold"
              href="https://preview-assets-eu-01.kc-usercontent.com/b50b8cce-450a-0138-7b43-de6bc2f3ad32/61406095-1d33-495c-9bbf-a3b6de942412/REGULAMENT_Campanie%20%E2%80%9CADUCI%20LA%20RECICLAT%2C%20AI%20DE%20C%C3%82%C8%98TIGAT%21%E2%80%9C%20.pdf"
              target="_blank"
            >
              aici
            </a> și <a
              className="font-weight-bold"
              href="https://assets-eu-01.kc-usercontent.com/b50b8cce-450a-0138-7b43-de6bc2f3ad32/b8aa199a-797e-4304-8422-1c1c68b023a5/Act%20Aditioal_REGULAMENT_Campanie%20Danone%2012%20luni.pdf"
              target="_blank"
            >
              aici
            </a>.{" "}
          </p>
        </div>
      </div>
      <div className="col-12 col-lg-6 order-1 order-lg-2" style={{ backgroundColor: '#DBCCB8' }}>
        <img src={PPRecicleaza} alt="reciclare" />
      </div>
    </div>{" "}
    <div className="section-info section-right row no-gutters">
      <div className="col-12 col-lg-6">
        <div
          className="section-bg h-100"
          style={{
            backgroundImage: `url(${PPBaterii})`,
            backgroundSize: "contain",
            backgroundColor: "#f8f8f8",
            backgroundPosition: "bottom center",
            backgroundRepeat: "no-repeat",
          }}
        />
      </div>
      <div className="col-12 col-lg-6">
        <div className="section-text py-6 py-lg-8 pr-20 pr-lg-6 pr-xl-8 pr-xxl-12 pl-20 pl-xl-8">
          <h1 className="text-success akko-bold mb-3">
            Reciclează baterii uzate împreună cu PENNY și ECOTIC BAT
          </h1>
          <p className="mb-0">
            Știai că din 100 de kilograme de baterii reciclate, putem recupera
            65 de kg de materii prime, pe care le putem reutiliza? Asta da veste
            bună pentru planetă, deci și pentru tine: mai puține resurse
            materiale necesare la fiecare producție de baterii.
            <br />
            <br />
            Cu mare bucurie, anunțăm parteneriatul cu organizația ECOTIC BAT,
            prin care îi sprijinim în demersul lor de conștientizare a
            importanței reciclarii bateriilor uzate. Astfel, în perioada
            01.01.2023 - 31.12.2023, vom organiza o campanie specială în toate
            magazinele PENNY.
            <br />
            <br />
            La 1 kg baterii uzate returnate în magazinele PENNY, primești
            gratuit un set de 8 baterii PENNY, de tip AA, de 1,5 V.
            <br />
            <br />
            Mai multe detalii în regulamentul oficial al campaniei{" "}
            <a
              className="font-weight-bold"
              href="https://assets-eu-01.kc-usercontent.com/b50b8cce-450a-0138-7b43-de6bc2f3ad32/ae4cfec1-0dcf-403b-ac8f-2af40391829f/REGULAMENT_Campanie%20PENNY%20Baterii_2024.pdf"
            >
              aici
            </a>
            .
          </p>
        </div>
      </div>
    </div>
    <div className="section-info section-left row no-gutters">
      <div className="col-12 col-lg-6 order-2 order-lg-1 align-self-center">
        <div className="section-text py-6 py-lg-8 pl-20 pl-lg-6 pl-xl-8 pl-xxl-12 pr-20 pr-xl-8">
          <h1 className="text-success akko-bold mb-3">
            DONEZI CAPACE DE PLASTIC, FACI O FAPTĂ BUNĂ!
          </h1>
          <p className="mb-0">
            Știți că, pe lângă pungile și recipientele de plastic, dopurile de
            plastic sunt unul dintre marii poluatori ai oceanelor și a mediului.
            Ele creează microbile care intră în lanțul trofic și poluează
            plajele. Prin colectarea selectivă a capacelor din plastic protejăm
            mediul înconjurător și contribuim la o eficiență sporită în
            utilizarea resurselor planetei.
            <br />
            Asociația Capace cu Suflet a dezvoltat un proiect inovator pentru
            colectarea dopurilor de sticlă aruncate, care ne protejează mediul
            și fauna sălbatică, ajutând, de asemenea, copiii aflați în nevoie.
            <br />
            Dacă ai capace din plastic, vino cu ele la PENNY, în magazinele
            partenere, și caută recipientul special pentru a le depune. Dopurile
            sunt adunate pentru a fi transportate ulterior către centre de
            reciclare, unde sunt revalorificate prin vânzare. Sumele obținute
            sunt completate de oameni inimoși, iar la final Asociația Capace cu
            Suflet, redirecționează banii obținuți înspre diverse cazuri
            umanitare.
            <br />
            Mai multe detalii în regulamentul oficial al campaniei care se
            găsește{" "}
            <a
              className="text-primary font-weight-bold"
              href="https://preview-assets-eu-01.kc-usercontent.com/b50b8cce-450a-0138-7b43-de6bc2f3ad32/12234726-d45d-48d3-8041-46e3dd2e8432/REGULAMENT_Capace%20cu%20suflet.pdf"
            >
              aici
            </a>
            .
          </p>
        </div>
      </div>
      <div className="col-12 col-lg-6 order-1 order-lg-2">
        <div
          className="section-bg h-100"
          style={{
            backgroundImage: `url(${PPCapace})`,
            backgroundSize: "contain",
            backgroundColor: "#EBEBEB",
            backgroundPosition: "top center",
            backgroundRepeat: "no-repeat",
          }}
        />
      </div>
    </div>{" "}
    <div className="section-info section-right row no-gutters">
      <div className="col-12 col-lg-6">
        <div
          className="section-bg h-100"
          style={{
            backgroundImage: `url(${PPPunga})`,
            backgroundSize: "contain",
            backgroundColor: "#EBEBEB",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
      </div>
      <div className="col-12 col-lg-6">
        <div className="section-text py-6 py-lg-8 pr-20 pr-lg-6 pr-xl-8 pr-xxl-12 pl-20 pl-xl-8">
          <h1 className="text-success akko-bold mb-3">
            Punem preț pe pungă. Punem preț pe responsabilitate.
          </h1>
          <p className="mb-0">
            La PENNY facem tot ce putem pentru o lume mai bună și un viitor mai curat. Un viitor care se face azi, cu fiecare pungă de plastic, fie ea și biodegradabilă, pe care nu o cumpărăm. Dacă îți faci cumpărăturile în perioada 17 ianuarie – 31 martie 2022 din
            {" "}<a
              className="font-weight-bold"
              href="/documente/magazine-partenere.pdf"
            >
              magazinele PENNY partenere
            </a>{" "}
            acestei acțiuni, contribui și tu la eliminarea plasticului. Poți achiziționa pungile biodegradabile compostabile de la raionul de legume la pretul de 0,09 lei/bucată. Ia astfel de pungi doar dacă este necesar. Vino cu punga de acasă și reduci astfel cantitățile de deșeuri generate!
          </p>
        </div>
      </div>
    </div>

    <div className="section-info section-left row no-gutters">
      <div className="col-12 col-lg-6">
        <div className="section-text py-6 py-lg-8 pr-20 pr-lg-6 pr-xl-8 pr-xxl-12 pl-20 pl-xl-8">
          <h1 className="text-success akko-bold">
            Ajutor umanitar pentru persoanele aflate in situatii vulnerabile
          </h1>
          <p className="mb-3">
            În această perioadă, ne bucurăm că putem contribui, alături de
            Banca pentru Alimente Oradea, Roman, Cluj, la sprijinirea
            persoanelor vulnerabile. Donează și tu în magazinele PENNY
            partenere din nord-vestul țării.
            <br />
            <br />
            Mai jos este disponibilă lista cu magazinele din țară unde se
            pot face donații.
          </p>
          <a
            href="https://preview-assets-eu-01.kc-usercontent.com/b50b8cce-450a-0138-7b43-de6bc2f3ad32/9ee540b6-fd09-4ca9-bd6a-a63d18b48655/2.pdf"
            className="btn btn-primary"
            target="_blank"
          >
            Magazine
          </a>
        </div>
      </div>
      <div className="col-12 col-lg-6">
        <div
          className="section-bg h-100"
          style={{
            backgroundImage: `url(${PPBanca})`,
            backgroundSize: "contain",
            backgroundColor: "#EBEBEB",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
      </div>


    </div>
  </Layout>
)

export default Energie
